import { Input, Flex, Select, Button, RangeSlider } from '@mantine/core';
import { Page } from '~/shared/components/Page';
import SearchIcon from '~/assets/icons/Search.svg';
import FilterIcon from '~/assets/icons/Filter.svg';
import SortIcon from '~/assets/icons/Sort.svg';
import CouponCard from './components/CouponCard';
import { useEffect, useState } from 'react';
import { Coupon } from '~/shared/types/Coupon';
import { useUserContext } from '~/shared/UserContext';
import getCoupons from '~/shared/api/getCoupons';
import buyCoupon from '~/shared/api/buyCoupon';
import {
	filterStyle,
	filterActiveStyle,
	sortIconStyle,
	filterContentStyle,
	filterContentActiveStyle,
	closeButton,
	filterTitleStyle,
	filterButtonStyle,
	filterButtonActiveStyle,
	filterListStyle,
	filterItemStyle,
	filterPriceStyle,
} from './styles.css';
import { Spinner } from '~/shared/components/Spinner';
import { ErrorNotice } from '~/shared/components/ErrorNotice';
import WebApp from '@twa-dev/sdk';
import getBalance from '~/shared/api/getBalance';
import { classNames } from '@tma.js/sdk';

import SuccessIcon from '~/assets/icons/Success.svg';

const orderOptions = ['Стоимость', 'Срок действия'];
const orderOptionsMap = {
	'Стоимость': 'price',
	'Срок действия': 'validity',
};

export const CouponPage = () => {
	const { user, setUser } = useUserContext();
	const [coupons, setCoupons] = useState<Coupon[]>([]);
	const [search, setSearch] = useState('');
	const [categories, setCategories] = useState<string[]>([]);
	const [brands, setBrands] = useState<string[]>([]);
	const [count, setCount] = useState<number>(0);

	const [priceRange, setPriceRange] = useState<[number, number]>([0, 0]);

	const [isFilter, setIsFilter] = useState(false);
	const [filters, setFilters] = useState<{
		price: [number, number];
		category: (typeof categories)[number][];
		brand: (typeof brands)[number][];
	}>({
		price: [0, 100000],
		category: [],
		brand: [],
	});

	const [openedList, setOpenedList] = useState<{
		category: boolean;
		brand: boolean;
	}>({ category: false, brand: false });

	const [status, setStatus] = useState('loading');
	const [error, setError] = useState('');
	const [orderBy, setOrderBy] = useState<(typeof orderOptions)[number]>('Стоимость');
	const [orderDir, setOrderDir] = useState<'asc' | 'desc'>('asc');
	const [disabled, setDisabled] = useState<number | null>(null);

	useEffect(() => {
		if (user?.id) {
			setStatus('loading');
			getCoupons(
				{
					user_id: user.id,
					category: filters.category,
					brand: filters.brand,
					orderBy: orderOptionsMap[orderBy],
					orderDir,
					minPrice: filters.price[0],
					maxPrice: filters.price[1],
				},
				(c) => {
					setCoupons(c.coupons);
					setCount(c.count);
					setCategories(c.categories);
					setBrands(c.brands);
					setPriceRange([c.minPrice, c.maxPrice]);
					setStatus('success');
				},
				(e) => {
					setStatus('error');
					setError(e.message);
				}
			);
		}
	}, [user, filters, orderBy, orderDir]);

	const handleClick = (coupon: Coupon) => {
		if (user?.id && !disabled) {
			setDisabled(coupon.id);
			buyCoupon(
				{ user_id: user.id, coupon_id: coupon.id },
				() => {
					WebApp.showPopup({
						title: 'Success',
						message: coupon.code ? `Code: ${coupon.code}` : '',
					});
					setDisabled(null);
					setStatus('loading');
					getCoupons(
						{
							user_id: user.id,
							category: filters.category,
							brand: filters.brand,
							orderBy,
							orderDir,
							minPrice: filters.price[0],
							maxPrice: filters.price[1],
						},
						(c) => {
							setCoupons(c.coupons);
							setCount(c.count);
							setCategories(c.categories);
							setBrands(c.brands);
							setPriceRange([c.minPrice, c.maxPrice]);
							setStatus('success');
						},
						() => {
							setStatus('error');
						}
					);
					getBalance(user.id, setUser);
				},
				(e) => {
					WebApp.showAlert(e.message ?? e.error);
					setDisabled(null);
				}
			);
		}
	};

	const currentDate = new Date();
	return (
		<Flex
			direction="column"
			align="center"
			w="100dvw"
			h="calc(100dvh - 65px)"
			justify="flex-start"
			style={{ overflowY: 'scroll', overflowX: 'hidden' }}>
			<Page
				headline={(user?.balance ?? 0).toString()}
				smallerHeadline="PONs"
				subheadline="Gold"
				childBoxStyle={{ width: 'calc(100% - 1.5em)', margin: '.5em .75em' }}>
				<Input
					placeholder="Поиск..."
					value={search}
					onChange={(e) => setSearch(e.target.value.toLowerCase())}
					leftSection={<SearchIcon />}
					style={{ borderRadius: 'xl' }}
				/>
				<div
					style={{
						display: 'grid',
						marginTop: '1rem',
						gap: '1ch',
						gridTemplateColumns: '1fr 1fr',
					}}>
					<Button
						variant="filled"
						color="grey.1"
						leftSection={<FilterIcon />}
						rightSection="Фильтры"
						onClick={() => setIsFilter((prev) => !prev)}
					/>
					<Select
						placeholder="Сортировка"
						variant="filled"
						color="grey.1"
						leftSection={
							<SortIcon
								onClick={() => setOrderDir(orderDir === 'asc' ? 'desc' : 'asc')}
								data-value={orderDir}
								className={sortIconStyle}
							/>
						}
						data={orderOptions}
						value={orderBy}
						onChange={(v) => setOrderBy(v ?? '')}
					/>
				</div>
				{status === 'loading' ? (
					<Spinner />
				) : status === 'error' ? (
					<ErrorNotice message={error} />
				) : (
					coupons
						.filter(
							(c) =>
								!search ||
								c.title?.toLowerCase().includes(search) ||
								c.value?.toLowerCase().includes(search) ||
								c.brand?.toLowerCase().includes(search) ||
								c.category?.toLowerCase().includes(search)
						)
						.filter(
							(c) =>
								!new Date(c.validity).getTime() ||
								new Date(c.validity).getTime() >= currentDate.getTime()
						)
						.map((coupon) => (
							<CouponCard
								key={`coupon-${coupon.id}`}
								coupon={coupon}
								handleClick={handleClick}
								isDisabled={disabled === coupon.id}
							/>
						))
				)}
			</Page>

			<div className={classNames(filterStyle, { [filterActiveStyle]: isFilter })}>
				<Flex
					direction="column"
					justify="space-between"
					className={classNames(filterContentStyle, {
						[filterContentActiveStyle]: isFilter,
					})}>
					<Flex
						style={{ overflow: 'auto', paddingBottom: 10 }}
						direction="column"
						gap={16}>
						<Flex align="center" justify="space-between" style={{ width: '100%' }}>
							<p className={filterTitleStyle}>Фильтры</p>

							<button
								onClick={() => {
									setIsFilter(false);
								}}
								className={closeButton}
							/>
						</Flex>
						<div>
							<p className={filterPriceStyle}>Стоимость</p>
							<Flex gap={16} align="center">
								<p>{priceRange[0]}</p>
								<RangeSlider
									minRange={1}
									value={filters.price}
									onChange={(price) => setFilters((prev) => ({ ...prev, price }))}
									min={priceRange[0]}
									max={priceRange[1]}
									style={{ width: '100%' }}
									thumbChildren={
										<div
											style={{
												borderRadius: '50%',
												width: 24,
												height: 24,
												boxShadow:
													'0 6px 13px 0 rgba(0, 0, 0, 0.12), 0 0px 4px 0 rgba(0, 0, 0, 0.12)',
												background: '#212121',
											}}
										/>
									}
									styles={{
										thumb: {
											border: 'none',
											padding: 0,
											width: 24,
											height: 24,
										},
										trackContainer: {
											height: 4,
										},
										track: {
											height: 4,
										},
										root: {
											height: 4,
										},
									}}
									color="#2990ff"
								/>
								<p>{priceRange[1]}</p>
							</Flex>
						</div>
						<Flex direction="column" gap={16}>
							<button
								onClick={() =>
									setOpenedList((prev) => ({ ...prev, category: !prev.category }))
								}
								className={classNames(filterButtonStyle, {
									[filterButtonActiveStyle]: openedList.category,
								})}>
								Категория
							</button>
							{openedList.category && (
								<div className={filterListStyle}>
									{categories.map((el, i) => (
										<Flex
											justify="space-between"
											key={el}
											className={filterItemStyle}
											style={{
												borderBottom:
													i !== categories.length
														? 'solid 0.5px rgba(255, 255, 255, 0.05)'
														: undefined,
											}}
											onClick={() =>
												setFilters((prev) => ({
													...prev,
													category: prev.category.includes(el)
														? prev.category.filter((type) => type !== el)
														: [...prev.category, el],
												}))
											}>
											{el}
											{filters.category.includes(el) && <SuccessIcon />}
										</Flex>
									))}
								</div>
							)}
						</Flex>
						<Flex direction="column" gap={16}>
							<button
								onClick={() => setOpenedList((prev) => ({ ...prev, brand: !prev.brand }))}
								className={classNames(filterButtonStyle, {
									[filterButtonActiveStyle]: openedList.brand,
								})}>
								Продавец
							</button>
							{openedList.brand && (
								<div className={filterListStyle}>
									{brands.map((el, i) => (
										<Flex
											justify="space-between"
											key={el}
											className={filterItemStyle}
											style={{
												borderBottom:
													i !== brands.length
														? 'solid 0.5px rgba(255, 255, 255, 0.05)'
														: undefined,
											}}
											onClick={() =>
												setFilters((prev) => ({
													...prev,
													brand: prev.brand.includes(el)
														? prev.brand.filter((type) => type !== el)
														: [...prev.brand, el],
												}))
											}>
											{el}
											{filters.brand.includes(el) && <SuccessIcon />}
										</Flex>
									))}
								</div>
							)}
						</Flex>
					</Flex>
					<Flex gap={16}>
						<button
							onClick={() => {
								setFilters({ price: [0, 12], category: [], brand: [] });
							}}
							style={{
								background: 'rgba(255, 255, 255, 0.08)',
								borderRadius: '8px',
								padding: '15px 12px',
								border: 'none',
								width: '50%',
								fontWeight: 600,
								fontSize: '17px',
								lineHeight: 1.53,
								letterSpacing: '0.01em',
								textAlign: 'center',
								color: '#fff',
							}}>
							Очистить
						</button>
						<button
							onClick={() => {
								setIsFilter(false);
							}}
							style={{
								background: '#ff8a00',
								borderRadius: '8px',
								padding: '15px 12px',
								border: 'none',
								width: '50%',
								fontWeight: 600,
								fontSize: '17px',
								lineHeight: 1.53,
								letterSpacing: '0.01em',
								textAlign: 'center',
								color: '#fff',
							}}>
							{count} {count % 10 === 1 ? 'купон' : (count % 10 === 2 || count % 10 === 3 || count % 10 === 4) ? 'купона' : 'купонов'}
						</button>
					</Flex>
				</Flex>
			</div>
		</Flex>
	);
};
