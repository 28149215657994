import { Flex, Box, Text, Progress } from "@mantine/core";
import { boxStyle, taskBlockStyle, bigBoxStyle, taskIconStyle, taskIconContainerStyle } from "./styles.css";
import { theme } from '~/shared/theme';
import { DailyTask } from "./DailyTask";
import { OtherTask } from "./OtherTask";

export type Task = {
    id: string;
    icon: string;
    title: string;
    reward: number;
    completed: boolean;
};

type Props = {
    tasks: Task[];
    achievements?: Task[];
    handleClick: (task: Task) => void;
}

export const Tasks = ({ tasks, achievements, handleClick }: Props) => {
    return (
        <Flex display='flex'
            direction='column'
            gap='1vh'
            justify='center'
            align='center'
            wrap='nowrap'
            style={{
                width: '100%',
                padding: '0 2em'
            }}
        >
            <div className={taskBlockStyle}>
                <Text style={{ fontWeight: 'bold' }}>Ежедневные задания</Text>
                {tasks.slice(0, 2).map(task => <DailyTask key={`task-${task.id}`} task={task} handleClick={handleClick} />)}

                <Flex
                    display='flex'
                    justify='center'
                    direction='row'
                    align='stretch'
                    wrap='wrap'
                    gap='2vw'
                >
                    {tasks.slice(2).map(task => <OtherTask key={`task-${task.id}`} task={task} handleClick={handleClick} />)}
                </Flex>
            </div>

            {!!achievements && (
                <div className={taskBlockStyle}>
                    <Text style={{ fontWeight: 'bold' }}>Achievements</Text>
                    {achievements?.map((task, index) => (
                        <Box
                            h='10vh'
                            p='.75rem'
                            className={`${boxStyle} ${bigBoxStyle}`}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                                overflow: 'hidden',
                                gap: '1em',
                            }}
                        >
                            <Box className={taskIconContainerStyle}>
                                <img src={task.icon} className={taskIconStyle} />
                            </Box>
                            <Box style={{ flex: '1 1 auto', overflow: 'hidden' }}>
                                <Text style={{ fontWeight: 'bold', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {task.title}
                                </Text>
                                <Text style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '0.7rem', color: 'grey' }}>
                                    {task.reward} PONs
                                </Text>
                            </Box>
                            <Box style={{ marginLeft: 'auto' }}>
                                {task.completed ? <Text style={{ fontSize: '1.5rem', color: 'orange' }}>✔️</Text> : null}
                            </Box>
                            <Progress color={theme.colors.accent[1]} value={69} style={{
                                flexBasis: '100%',
                            }}></Progress>
                        </Box>
                    ))}
                </div>
            )}
        </Flex>
    );
};
