import { useState, useRef, useEffect } from 'react';
import { Page } from '~/shared/components/Page';
import poncoin from '~/assets/img/coupon.png';
import { Flex } from '@mantine/core';
import { medalImgStyle, progressBarStyle, clickTextStyle, comeBackContainer } from './styles.css';
import { ProgressBar } from './components/ProgressBar/index';
import { useUserContext } from '~/shared/UserContext';
import tap from '~/shared/api/tap';

// Define the type for click objects
type Click = {
    id: number;
    x: number;
    y: number;
};

export const ClickPage = () => {
    const { user, setUser } = useUserContext();
    const [clicks, setClicks] = useState<Click[]>([]); // Use the Click type here
    const [lastClick, setLastClick] = useState(-1);
    const maxValue = 1000;
    const clickCounter = useRef(0);

    const clickValue = 10;
    const handleImageClick = (e: React.MouseEvent<HTMLImageElement>) => {
        if (user && user.energy >= clickValue) {
            const rect = e.currentTarget.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;

            const newClick: Click = {
                id: clickCounter.current++,
                x,
                y,
            };

            setClicks(prevClicks => [...prevClicks, newClick]);
            setUser({ ...user, balance: user.balance + clickValue, energy: user.energy - clickValue });

            clearTimeout(lastClick);
            const newTimeout = setTimeout(() => {
                tap(
                    { id: user.id, balance: user.balance },
                    u => u.reset_energy_ts !== user.reset_energy_ts && setUser(u)
                );
            }, 1000);
            setLastClick(newTimeout);
        }
    };

    useEffect(() => {
        if (clicks.length > 0) {
            const timer = setTimeout(() => {
                setClicks(prevClicks => prevClicks.slice(1));
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [clicks]);

    const progressPercentage = ((user?.energy ?? 0) / maxValue) * 100;

    return (
        <Flex
            display='flex'
            direction='column'
            align='center'
            w='100dvw'
            h='calc(100dvh - 65px)'
            bg={'radial-gradient(circle at 50% 50%, rgba(255, 138, 0, 0.6), #000000)'}
            bgsz='cover'
            justify='space-between'
            style={{ overflowY: 'scroll', overflowX: 'hidden' }}
        >
            <Page headline={(user?.balance ?? 0).toString()} smallerHeadline='PONs' subheadline="Gold">
                <Flex display='flex' align='center' justify='center' style={{ position: 'relative' }}>
                    <img
                        src={poncoin}
                        className={medalImgStyle}
                        onClick={handleImageClick}
                        data-disabled={user?.energy === 0}
                    />
                    {user?.energy === 0 &&
                        <div className={comeBackContainer}>
                            Возвращайся через 6 часов
                        </div>
                    }
                    {clicks.map(click => (
                        <span
                            key={click.id}
                            className={clickTextStyle}
                            style={{
                                top: `${click.y - 25}px`,
                                left: `${click.x}px`,
                                transform: 'translate(50%)',
                            }}
                        >
                            +{clickValue}
                        </span>
                    ))}
                </Flex>
            </Page>
            <ProgressBar
                className={progressBarStyle}
                progress={progressPercentage}
                currentProgress={user?.energy ?? 0}
                maxValue={maxValue}
            />
        </Flex>
    );
};
