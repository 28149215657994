import { Button } from '@mantine/core';
import { theme } from '~/shared/theme';
import { Coupon } from '~/shared/types/Coupon';
import CrossIcon from '~/assets/icons/cross.svg';
import { createPortal } from 'react-dom';
import { codeContainerStyle, dialogStyle, shadowStyle, dialogHeaderStyle, codeStyle, fullDetailDialogStyle, fullDetailLogoContainerStyle, fullDetailTextContainerStyle, fullDetailValueStyle, fullDetailContentStyle, fullDetailTitleStyle, logoStyle, brandStyle, validUntilStyle } from './styles.css';

type Props = {
    dialogType: 'code' | 'details' | null;
    coupon: Coupon;
    onClose: () => void;
    onBuy: (coupon: Coupon) => void;
    isDisabled?: boolean;
};

const CouponPopup = ({ dialogType, coupon, onClose, onBuy, isDisabled }: Props) => {
    if (!dialogType) return null;

    const logo = import.meta.env.VITE_API_URL + coupon.logo;
    const validity = new Date(coupon.validity);
    const formattedDate = validity.getTime() ? validity.toLocaleDateString('en', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    }) : '';

    return createPortal(
        <>
            <div className={shadowStyle} onClick={e => { e.preventDefault(); e.stopPropagation(); onClose(); }} />
            {dialogType === 'code' ? (
                <div className={dialogStyle} onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
                    <div
                        style={{ position: 'absolute', top: '12px', right: '20px', cursor: 'pointer' }}
                        onClick={onClose}
                    >
                        <CrossIcon />
                    </div>
                    <div className={dialogHeaderStyle}>Код:</div>
                    <div className={codeStyle}>{coupon.code}</div>
                </div>
            ) : (
                <div className={fullDetailDialogStyle} onClick={e => { e.preventDefault(); e.stopPropagation(); }}>
                    <div
                        style={{ position: 'absolute', top: '12px', right: '20px', cursor: 'pointer' }}
                        onClick={onClose}
                    >
                        <CrossIcon />
                    </div>
                    <div className={fullDetailContentStyle}>
                        <div className={fullDetailLogoContainerStyle}>
                            <img src={logo} alt="Logo" className={logoStyle} />
                            <span className={brandStyle}>{coupon.brand}</span>
                        </div>
                        <div className={fullDetailTextContainerStyle}>
                            <div className={fullDetailTitleStyle}>{coupon.title}</div>
                            <div className={fullDetailValueStyle}>{coupon.value}</div>
                            <div className={validUntilStyle}>
                                {formattedDate ? `Действителен до ${formattedDate}` : ''}
                            </div>
                        </div>
                    </div>
                    {!coupon.is_used ? (
                        <Button
                            fw='normal'
                            color={theme.colors.orange[5]}
                            variant='filled'
                            radius="xl"
                            onClick={(e) => {
                                e.stopPropagation();
                                onBuy(coupon);
                                onClose();
                            }}
                            disabled={isDisabled}
                            fullWidth
                        >
                            Купить за {coupon.price} PONs
                        </Button>
                    ) : (
                        <div className={codeContainerStyle}>
                            <div className={dialogHeaderStyle}>Код:</div>
                            <div className={codeStyle}>{coupon.code}</div>
                        </div>
                    )}
                </div>
            )}
        </>, document.body
    );
};

export default CouponPopup;
