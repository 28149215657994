import { headers } from './headers';
import { Coupon } from '../types/Coupon';

type Data = {
	user_id: number;
	category?: string[];
	brand?: string[];
	orderBy?: string;
	orderDir?: string;
	minPrice?: number;
	maxPrice?: number;
};
const getCoupons = async (
	data: Data,
	onSuccess?: (val: {
		coupons: Coupon[];
		count: number;
		categories: string[];
		brands: string[];
		minPrice: number;
		maxPrice: number;
	}) => void,
	onError?: (e: any) => void
) => {
	const { user_id, category, brand, orderBy, orderDir, minPrice, maxPrice } = data;
	const args: string[] = [];
	if (category && category.length > 0) {
		args.push(`category=${category.map((el) => el).join(',')}`);
	}
	if (brand && brand.length > 0) {
		args.push(`brand=${brand.toString()}`);
	}
	if (orderBy) {
		args.push(`orderBy=${orderBy.toLowerCase()}`);
	}
	if (orderDir) {
		args.push(`orderDir=${orderDir.toLowerCase()}`);
	}
	if (minPrice) {
		args.push(`minPrice=${minPrice}`);
	}
	if (maxPrice) {
		args.push(`maxPrice=${maxPrice}`);
	}
	const collectedArgs = args.length ? `?${args.join('&')}` : '';
	try {
		const response = await fetch(
			`${import.meta.env.VITE_API_URL}/coupons/${user_id}${collectedArgs}`,
			{ headers }
		);
		const res = await response.json();
		if (response.ok) {
			console.log(res);

			onSuccess?.(res);
		} else {
			onError?.(res);
		}
	} catch (e) {
		console.error(e);
		onError?.(e);
	}
};

export default getCoupons;
